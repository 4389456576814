.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #D8BF99;
    color: #000000;
    z-index: 1000;
    text-align: center;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 40%) 0px 4px 8px;
}

.icon {
    margin-top: 14px;
    animation: scrollTop 0.5s alternate ease infinite;
}

@keyframes scrollTop{
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);

    }
}
