.green-btn {
    background: #CCD5AE;
    border-radius: 20px;
    padding: 1rem;
}

.budget___search {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.budget___col-2 {
    display: flex;
}

#add-expense-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}


.budget___select {
    width: 100%;
}

.budget___select > select {
    border-radius: 5px;
    padding: 0.6rem;
    border-color: #ccc;
    width: 100%;
}
.budget___col-1 {
    display: flex;
    flex-direction: row;
}

.budget___col-1 > .chart-component{
    width: 60%;
}

.budget___col-1 > .chart-component > .pie > canvas {
    height: 15rem;
}
.card-expense-component {
    padding: 0;
}
.card-expense-component > .card {
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
    padding: 1rem 1rem !important;
    flex-direction: column;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    justify-items: center;
    text-align: center;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
    border: solid 1px rgb(151, 151, 151, 0.18);
    padding: 2rem 1rem !important;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    height: fit-content;
    justify-items: center;
    text-align: center;
    /* border-radius: 0; */
}

.budget-list {
    width: 100%;
    margin-top: 50px;
    padding: 0;
    /* container-type: inline-size; */
}

.budget-list li {
    border-radius: 5px;
    padding: 25px 30px;
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 25px;
}
.budget-list .table-header {
    background-color: #323535;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: #FFF
}
.budget-list .table-row {
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.budget-list .cols-1 {
    width: 20%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
}
.budget-list .cols-2 {
    width: 15%;
    align-self: center;
    padding-left: 5px;
    padding-right: 5px;
    /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.budget-list .cols-3 {
    width: 15%;
    align-self: center;
    padding-left: 5px;
    padding-right: 5px;
    /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.budget-list .cols-4 {
    width: 40%;
    align-self: center;
    padding-left: 5px;
    padding-right: 5px;
}
.budget-list .cols-5 {
    width: 10%;
    align-self: center;
    padding-left: 5px;
    padding-right: 5px;
}

.search-bar-wrapper {
    display: flex;
    justify-content: flex-end;
}
.budget-list #empty {
    text-align: center;
}

@container (max-width: 768px){
    .budget-list li {
        padding-left: 10px ;
    }
    .budget-list .cols-1 {
        width: 25%;
    }
    .budget-list .cols-2 {
        width: 15%;
    }
    .budget-list .cols-4 {
        width: 30%;
    }
    .budget-list .cols-5 {
        width: 10%;
    }
    .budget-list .table-header {
        display: none;
   }
    .budget-list li {
        display: block;
   }
    .budget-list .cols  {
        width: 100%;
        align-items: center;
   }
    .budget-list .cols  {
        display: flex;
        padding: 10px 0;
   }
    .budget-list .cols:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        width: 50%;
        text-align: right;
   }
   .budget___col-2 {
    padding-left: 0px;
    padding-right: 0px;
}
}

@media (max-width: 768px) { 
    .budget___col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .card-component {
        align-self: initial;
        width: 80%;
    }
    .chart-component {
        margin-top: 3rem;
        padding: 0;
    }
    .pie > canvas {
        height: 20rem;
    }
    .budget___col-2 > ul {
        padding: 0;
    }
    .budget___search {
        justify-content: center;
    }
    .budget___search > div {
        width: 70%;
    }
    .budget___col-1, .pie {
        padding-left: 0px;
        padding-right: 0px;
    }
 }

@media (max-width: 600px) {
    .budget___col-1 {
        padding: 0;
    }
    .card-expense-component {
        margin-top: 30px;
    }
}

@container (max-width: 768px) { /* inférieur à 768px */
    .budget-list .table-header {
        display: none;
    }
    
  }

@media (max-width: 414px) { 

    .budget-form___style {
        font-size: 75%;
    }
    .budget___col-2 > ul {
        padding: 0;
    }
    .budget-list .cols  {
        text-align-last: start;
   }
    
    .budget___col-2 {
        padding: 0;
    }
    .chart-component {
        padding: 0;
    }
}