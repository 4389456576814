.page-component {
    width: 100%;
    padding-bottom: 100px;
}
.page-container {
    padding: 3rem;
    container-type: inline-size;
    max-width: 1320px;
    width: 100%;
}
#main-content {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width : 1111px) {
    .page-container {
        padding: 2rem;
    }
}