.card-body {
    display: flex;
    flex-direction: column;
}
.card-body > h5 {
    font-size: 1rem;
    text-transform: uppercase;
    font-family: Playfair Display, serif;
    color: #878a8c;
    letter-spacing: 1px;
}
.card-body > span {
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3em;
    text-align: center;
}

@media (max-width: 1024px) { 
    .card-body > span {
        font-size: 2.5rem;
    }

}

@media (max-width: 768px) { 
    .card-body > span {
        font-size: 2rem;
    }
}