.account__row {
    display: flex;
    margin-bottom: 20px;
    min-height: 50px;
    width: 100%;
}

.account__row > span {
    padding-left: 1.5rem;
}

.account___form {
    height: fit-content;
    margin: 0;
    padding: 100px 0;
}

.account___settings {
    height: 200px;
    width: 100%;
}

#delete-account___link {
    margin-top: 50px;
    flex-direction: column;
    /* font-size: 0.7rem; */
}

#delete-account___link > span {
    text-decoration: underline;
    cursor: pointer;
}


#delete-account___link > div {
    padding-left: 1.5rem;
    /* font-size: 0.7rem; */
}

#delete-account___link > div > button {
    margin: 0px 5px;
}

.account-form__style {
    width: 100%;
}

.account___form-style, .wedding-form__style {
    width: 100%;
}
.account-container {
    padding: 40px;
    max-width: 950px;
}
.settings-img-container > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.settings-img-container > div > img {
    border-radius: 50%;
    height: 11rem;
    width: auto;
    object-fit: contain;
}
#modal-account {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
#settings-img-lock {
    display: none;
}
.settings-title {
    font-size: 1.3rem;
    letter-spacing: -1px;
    color: #878a8c;
    font-weight: 300;
}   
.settings-container {
    /* container-type: inline-size; */
    padding: 2rem 50px; 
    display: flex; 
    flex-direction: column; 
    gap: 30px; 
    border-radius: 10px; 
    border: 1px solid #dedbdb; 
    background-color: #fbfbfb; 
    max-width: 1000px; width: 100%;
}
#settings-divider {
    display: none;
}
@media (max-width: 1200px) {
	.settings-img-container {
        flex-grow: unset;
        flex-basis: unset;
        width: 35%;
    }
    .settings-content-container {
        flex-grow: unset;
        flex-basis: unset;
        width: 65%;
    }
    .settings-img-container > div > img {
        width: 100%;
        height: auto;
    }
    .account-container {
        padding: 40px;
    }
}

@media (max-width: 767.98px) { 
    .account___img {
        display: none;
    }

    .account___form-style {
        padding-right: 1.5rem;
    }
    /* .account-container .row {
        flex-direction: column;
        gap: 20px;
    } */
    .settings-content-container {
        width: 100%;
    }
    .settings-img-container {
        width: 100%;
    }
    .settings-img-container > div > img {
        width: auto;
        height: 8rem;
    }
}
@container (max-width: 570px){
    .account-container .row {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        margin-top: 50px;
    }
    .settings-content-container {
        width: 100%;
    }
    #settings-divider {
        display: flex;
        background-color: #c5c4c4;
        width: 100%;
    }
    .account-container {
        padding-left: 0;
        padding-right: 0;
    }
}
@media (max-width: 500px) { 
    .settings-container {
        padding: 2rem 30px;
        border: unset;
        padding: unset;
    }
    .account-container {
        padding-top: 20px;
    }
}