#home-header-wrapper {
    background-color: #F0EEEC;
    height: 750px;
}

#home-header-wave {
    display: block;
    position: relative;
    height: 750px;
    width: 100%;
    /* background: rgb(57, 27, 112); */
    transform: scale(1, 1);
}
  
  #home-header-wave:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: #ffffff ;
    right: -25%;
    top: 730px;
  }
  
  #home-header-wave:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    /* background-color: rgb(57, 27, 112); */
    background-color:#F0EEEC;
    left: -25%;
    top: 470px;
    clip-path: ellipse(100% 15% at -15% 100%);
  }

#home-header {
    display: flex;
    flex-direction: row;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 75%;
    background-color: inherit;
}
#header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
}
#header-text .big-text{
    display: flex;
    flex-direction: column;
    line-height: 0.85;
    margin-bottom: 20px;
}
#header-text .big-text span {
    font-size: 5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 300;
}
#header-text .big-text span:nth-child(3) {
    font-weight: 900;
    margin-bottom: 1rem;
}
#header-text .small-text{
    display: flex;
    flex-direction: column;
    max-inline-size: 70ch;
    text-wrap: balance;
    font-size: 1.2rem;
}

#header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
#home-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
}
.img-container {
    width: 500px;
    max-width: 500px;
    height: 100%;
    max-height: 500px;
    background-color: #97877D;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
#small-text-sm {
    display: none;
}
@media (max-width: 1200px){
    #home-header-wrapper {
        /* height: 650px; */
    }
    #home-header{
        margin: 0;
        padding-left: 100px;
        padding-right: 100px;
    }
    .img-container {
        width: 35vw; 
        max-width: 500px;
        height: 35vw; 
    }
    #header-text .big-text span {
        font-size: 3.5rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 300;
    }
}
@media (max-width: 900px){
    #home-header {
        flex-direction: column;
        margin-top: 50px;
        padding: 0;
        gap: 30px;
    }
    #home-header-wave {
        height: 660px;
    }
    #home-header-wave:after {
        top: 380px;
    }
    #home-header-wave:before {
        top: 640px;
    }
    #header-image {
        justify-content: end;
        flex: unset;
    }
    .img-container {
        width: 50%;
        max-width: unset;
        border-radius: 0;
        justify-content: unset;
        overflow: unset;
        height: 170px;
    }
    #header-text .small-text{
        display: none;
    }
    #header-text .big-text{
        z-index: 1;
    }
    #header-text .big-text span {
        font-size: 2.5rem;
    }
    #header-text{
        padding-left: 100px;
        margin-left: 0;
    }
    #home-image {
        background-color: #97877D;
        right: 83%;
        border-radius: 50% 0 0 50%;
    }
    #small-text-sm {
        display: flex;
        flex-direction: column;
        padding-left: 60px;
        padding-right: 60px;
        margin-top: 40px;
        text-align: center;
        max-width: 700px;
        width: 100%;
        place-self: center;
    }
    #small-text-sm span:nth-child(1) {
        font-size: 2rem;
        font-weight: 900;
    }
    #home-header-wrapper{
        /* padding-bottom: 150px; */
        height: fit-content;
    }
}
@media (max-width: 768px){
    #header-text .big-text span {
        font-size: 2.5rem;
    }
    #header-text {
        padding-left: 45px;
    }
}
@media (max-width: 576px){
    #small-text-sm span:nth-child(1) {
        font-size: 1.5rem;
    }
    #small-text-sm {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 350px){
    #home-image {
        height: 100%;
    }
}