

form > .form-group > span {
	font-size: 80%;
    color: darkred;
	padding: 0.5rem 1rem;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
	/* border: none; */
	-webkit-text-fill-color: #000000;
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
}

.login__row__icon > svg {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	overflow: visible;
}

.svg-icon {
	cursor: pointer;
}

.svg-icon path {
	stroke: rgba(255, 255, 255, 0.9);
	fill: none;
	stroke-width: 1;
}

input, button {
	outline: none;
	border: none;
}

.register-grid {
	display: flex;
    height: 100%;
}

.register-grid > .grid-item-1 {
	position: absolute;
    width: 50%;
    height: inherit;
}

.register-grid > .grid-item-1 > img {
	object-fit: cover;
    width: 100%;
    height: 100%;
	/* object-position: right; */
}

.register-grid > .grid-item-2 {
	height: fit-content;
    padding: 0 1rem;
    width: 100%;
    background-color: #FFF;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.register {
	display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    gap: 20px;
	max-width: 23rem;
}

.register__form > form {
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
}

.register__form > form > div {
    display: flex;
    flex-direction: column;
}

.register__form > form > div > input[type=submit] {
	background: #CCD5AE;
    border-radius: 5px;
    padding: 1rem;
}

.register__form > form > div > input[type=submit]:hover {
	background: #d7e0b8;
}

.register.inactive {
	opacity: 0;
	transform: scale(1.1);
}



.register__row {
	display: flex;
	margin-bottom: 20px;
    min-height: 50px;
	width: 100%;
}

.register__row__icon {
	display: flex;
	align-self: center;
}

.register__icon {
	margin-bottom: -0.4rem;
	margin-right: 0.5rem;
}

.register__icon.name path {
	stroke-dasharray: 73.50196075439453;
	stroke-dashoffset: 73.50196075439453;
	animation: animatePath 2s 0.5s forwards;
}

.register__icon.pass path {
	stroke-dasharray: 92.10662841796875;
	stroke-dashoffset: 92.10662841796875;
	animation: animatePath 2s 0.5s forwards;
}

.register__input {
	display: inline-block;
	/* width: 22rem;
	height: 100%; */
	padding-left: 1.5rem;
	padding-bottom: 10px;
	/* font-size: 1.5rem; */
	height: 100%;
	background: transparent;
	color: #FDFCFD;
	border-bottom: 1px solid #FFFFFF;
}

.register__submit {
	position: relative;
	width: 100%;
	height: 4rem;
	margin: 5rem 0 2.2rem;
	color: rgba(255, 255, 255, 0.8);
	background: #FF3366;
	font-size: 1.5rem;
	border-radius: 3rem;
	cursor: pointer;
	overflow: hidden;
	transition: width 0.3s 0.15s, font-size 0.1s 0.15s;
}

.register__submit:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -1.5rem;
	margin-top: -1.5rem;
	width: 3rem;
	height: 3rem;
	border: 2px dotted #fff;
	border-radius: 50%;
	border-left: none;
	border-bottom: none;
	transition: opacity 0.1s 0.4s;
	opacity: 0;
}

.register__submit.processing {
	width: 4rem;
	font-size: 0;
}

.register__submit.processing:after {
	opacity: 1;
	animation: rotate 0.5s 0.4s infinite linear;
}

.register__submit.success {
	transition: transform 0.3s 0.1s ease-out, opacity 0.1s 0.3s, background-color 0.1s 0.3s;
	transform: scale(30);
	opacity: 0.9;
}

.register__submit.success:after {
	transition: opacity 0.1s 0s;
	opacity: 0;
	animation: none;
}

.register__signup {
    color: #ABA8AE;
	height: 15%;
	margin-top: 2rem;
	display: flex;
	flex-direction: row !important;
	justify-content: center;
	gap: 4px;
}

.register__signup:nth-child(even) {
	text-align: center;
}

.register__signup a {
	color: #000000;
	cursor: pointer;
	text-decoration: underline;

}


.ripple {
	position: absolute;
	width: 15rem;
	height: 15rem;
	margin-left: -7.5rem;
	margin-top: -7.5rem;
	background: rgba(0, 0, 0, 0.4);
	transform: scale(0);
	animation: animRipple 0.4s;
	border-radius: 50%;
}

@keyframes animRipple {
	to {
		transform: scale(3.5);
		opacity: 0;
	}
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

@keyframes animatePath {
	to {
		stroke-dashoffset: 0;
	}
}


@media (max-width: 1024px) {
	.footer-dims, .navbar-nav, .dropdownmenu > a {
		font-size: 1rem;
	}
}

@media (max-width: 767px) {
	.register-grid > .grid-item-1 {
		display: none;
	}
	.register-grid > .grid-item-2 {
		width: 100%;
	}
}

@media (max-width: 414px) {
	.register-grid > .grid-item-2 {
		padding: 40px 40px;
	}
}