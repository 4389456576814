.nav-open .custom-sidebar {
    width: 330px;
}
.custom-sidebar {
width: 70px;
height: 100vh;
position: fixed;
top: 64px;
/* left: 0; */
height: 100%;
background: #fcfaf7;
transition: width 0.3s;
overflow: auto;
border-right: 1px solid #cecdcc;
}

.custom-sidebar.open {
    /* width: 100%; */
}

.custom-sidebar-inner {
position: absolute;
top: 0;
left: 0;
width: 100%;
}

.custom-sidebar-header {
display: block;
align-items: center;
height: 60px;
background: rgb(0 0 0 / 15%);
}

.custom-sidebar-burger {
background: transparent;
border: 0;
padding: 0;
cursor: pointer;

width: 60px;
height: 60px;
display: grid;
place-items: center;
color: grey;
}

.custom-sidebar-logo {
height: 28px;
}

.custom-sidebar-menu {
display: grid;
padding: 10px;
}

.custom-sidebar-button {
display: flex;
gap: 16px;
align-items: center;
height: 56px;
width: 100%;
line-height: 1;
padding: 0 10px;
border-radius: 8px;
color: #534a43;
opacity: 0.8;
background-color: unset;
}

.custom-sidebar-button:hover {
background: #e0dedb;
/* color: #fcfaf7; */
opacity: 1;
}

.custom-sidebar-button:hover > span {
opacity: 1;
}

.custom-sidebar-logo,
.custom-sidebar-button p {
opacity: 0;
transition: 0.3s;
margin-bottom: 0;
}

.custom-sidebar.open :is(.custom-sidebar-button p, .custom-sidebar-logo) {
opacity: 1;
}

.custom-sidebar-button > img {
width: 24px;
height: 24px;
}

.custom-sidebar-button > span {
opacity: 0.5;
padding: 5px;
}
.picture-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 15px;
}
.picture {
    max-height: 50px;
    max-width: 50px;
    display: flex;
    justify-content: center;
}
.xs-picture {
    max-height: 120px;
    max-width: 120px;
}
.xs-img {
    border: unset !important;
}
.picture > img {
    border-radius: 50%;
    max-height: inherit;
    border: 7px solid #D8BF99;
}

@media screen and (max-width: 768px){
    .custom-sidebar{
        /* position: absolute; */
        z-index: 100;
    }
}

@media only screen and (max-width: 600px){
	.custom-sidebar {
        display: none;
    }
}