.titles {
    /* text-shadow: 1px 1px 1px rgba(238, 235, 235, 0.85); */
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #323535;
    display: flex;
    align-items: center;
    min-height: 60px;
    height: fit-content;
    text-transform: uppercase;
    position: sticky;
    top: 64px;
    z-index: 100;
}
.titles > h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    color: #FFF;
    letter-spacing: 1px;
}