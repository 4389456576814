#add-table-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}