#tablelist-container {
    width: 100%;
}
#tablelist-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
}

@container (max-width: 650px){
    #tablelist-content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@container (max-width: 450px){
    #tablelist-content {
        grid-template-columns:1fr;
    }
}