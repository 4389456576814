#tr-description {
    align-self: unset;
}

@container (min-width: 768px) { /* supérieur à 640px */
    .table-header {
        background-color: red;
    }
    .expense-card {
        display: flex;
    }
  }