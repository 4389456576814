
#add-task-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.select-tasks {
  padding-left: 15px;
  padding-right: 15px;
  font-family: Playfair Display, serif !important;
  height: fit-content;
  background-color: #FFF;
}

.MuiButtonBase-root {
  /* font-family: Playfair Display, serif !important; */
}

.tasks-title {
  display: flex;
}
.select-family {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 27rem;
}
.select-family div:nth-child(1) {
  margin: 0;
}
.task-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 786px) {
  .tasks-title {
    display: none;
  }

  .add-task-form {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .tasks-count {
    font-size: 1.3rem !important;
  }
}
#empty-task {
  align-self: center;
  margin-top: 5rem;
  font-size: 1.3rem;
}
@container (max-width: 600px){
 
}

@media (max-width: 414px) {
    .todolist___checkbox_span {
        margin-right: 10px;
    }
}