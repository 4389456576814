/* enter */
.fade-enter {
    opacity: 0;
    z-index: 1;
  }
  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 5000ms linear 5000ms;
  }

/* slide enter
.slide-enter {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    z-index: 1;
  }
  .slide-enter.slide-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
  } */
.item-fadeout {
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}

@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  @keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

.byguests___block {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.guest-card__delete-btn {
    display: flex;
    justify-content: flex-end;
}

.guest-card__delete-btn > button {
    background: none;
    font-size: 1.5rem;
    color: grey;
}


.block {
    width: 100%;
    text-align: center;
    border-radius: 10px;
}

.empty-tab > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.div-guest > button {
    position: absolute;
    bottom: 86%;
    left: 86%;
    color: #D3D3D3;
}

.div-guest___container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.guests___li-btns > button {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0px 5px;
    padding: .375rem 1.5rem;
    box-shadow: rgb(0 0 0 / 26%) 0px 1px 3px 0px;
    background-color: #f6f5f3;
    border-radius: 20px;
}

.group-name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-transform: uppercase;
}

.guest-card__namefield-container {
    display: flex;
    flex-direction: column;
    color: #737171;
    margin-bottom: 50px;
    word-break: break-word;
}

.nameField {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    word-break: break-word;
}
.nameField > div {
    display: flex;
    flex-direction: column;
    line-height: 25px;
    margin-top: 12px;
}

#guest-name {
    color: #000;
    font-family: Playfair Display, serif;
    font-size: 1.1rem;
    font-weight: 500;
    word-break: break-word;
    line-height: 1.4;
    /* word-spacing: 4px; */
}

.guest-family {
    color: grey;
    font-size: 0.85rem;
}

.nameField > span {
    color: #a0a0a0;
}

.nameField > form > input {
    width: 80%;
}

.action-buttons {
    display: flex;
    /* flex-direction: row; */
    gap: 7px;
    justify-content: space-between;
    flex-wrap: wrap;
    }

.action-buttons > button {
    text-transform: unset;
    font-family: unset;
    font-size: 1rem !important;
    }

.guest-picture {
    width: 100%;
}


.guest-picture > img {
    object-fit: cover;
    width: 100%;
    height: 10rem;
    border-radius: 5px;
}

#upload-avatar {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-bottom: 15px;
    background-image: repeating-linear-gradient(0deg, #bfbfbf, #bfbfbf 7px, transparent 7px, transparent 11px, #bfbfbf 11px), repeating-linear-gradient(90deg, #bfbfbf, #bfbfbf 7px, transparent 7px, transparent 11px, #bfbfbf 11px), repeating-linear-gradient(180deg, #bfbfbf, #bfbfbf 7px, transparent 7px, transparent 11px, #bfbfbf 11px), repeating-linear-gradient(270deg, #bfbfbf, #bfbfbf 7px, transparent 7px, transparent 11px, #bfbfbf 11px);
    background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
}
#upload-avatar > label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
#upload-avatar > img {
    height: 4rem;
    width: fit-content;
}
#upload-avatar > label > img {
    height: 4rem;
    width: fit-content;
}

.guest-picture > div > button {
    border-radius: 50%;
    padding: 8px;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #000;
    color: #fff;
}

.modal___picture {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    height: 10rem;
    justify-content: space-around;
}

.modal___picture > button {
    padding: 0.7rem;
    border-radius: 5px;
    margin-top: 10px;
}

.custom-dropdown {
    width: 100%;
}

.dropdown {
    text-align: flex-end;
    text-decoration-style: none;
    text-decoration: none;
    text-decoration-line: none;
}

.dropdown:hover {
    text-decoration-style: none;
    text-decoration: none;
    text-decoration-line: none;
}

.dropdown > a {
    position: absolute;
    top: -15px;
    right: -8px;
    cursor: pointer;
}

.dropdown > a:hover {
    text-decoration-style: none;
    text-decoration: none;
    text-decoration-line: none;
}

.dropdown > a:active {
    text-decoration-style: none;
    text-decoration: none;
    text-decoration-line: none;
}
.chose-family {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    margin-top: 15px;
}
.fam-input-container {
    display: flex;
}
.fam-input-container input[type="radio"] {
    position: absolute;
	 opacity: 0;
}
.fam-input-container input[type="radio"] + .choose-fam-label:before {
    content: '';
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    top: -0.2em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    margin-top: 3px;
}
.fam-input-container input[type="radio"]:checked + .choose-fam-label:before {
    background-color: #bb9c71;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}
.fam-input-container input[type="radio"]:focus + .choose-fam-label:before {
    outline: none;
	 border-color: #bb9c71;
}
.fam-input-container input[type="radio"] + .choose-fam-label:empty:before {
    margin-right: 0;
}

.guest-card-style {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
    padding: 1rem 1rem !important;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    background-color: #f9f9f9;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* box-sizing: initial; */
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
}

.guest-wrapper {
    min-height: 270px;
    height: 100%;
    transition: filter 0.5s ease;
    display: flex;
    cursor: pointer;
    background: var(--light);
    box-shadow: var(--shadow);
    color: var(--dark);
    text-align: center;
    flex-direction: column-reverse;
    align-items: stretch;
    box-sizing: border-box;
}


.guests-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 200px));
    grid-gap: 15px 15px;
    justify-content: center;
    align-items: baseline;
 }
 .select-guest {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 27rem;
}
.select-guest div:nth-child(1) {
    margin: 0;
}

 @media(max-width: 1366px) {
    .guests-container {
        grid-template-columns: repeat(auto-fill, minmax(50px, 145px));
     }
  }


  @media(max-width: 954px) {
    .byguests___block {
        padding: 0;
    }
  }

  @media(max-width: 845px) {
    .guests-container {
        /* grid-template-columns: repeat(auto-fill, minmax(50px, 135px)); */
    }
  }

  @media(max-width: 767px) {
    .guest-picture > img {
        height: 8rem;
    }
    #guest-name {
        font-size: 1rem;
        line-height: normal;
    }
    .guest-family {
        font-size: 0.8rem;
        line-height: normal;
    }
    .guest-wrapper {
        min-height: unset;
    }
  }

@media (max-width: 600px) {
    .guest-wrapper {
       flex-basis: 33.33%;
     }
    
    .guest-card-style {
       min-width: unset !important;
       width: 100% !important;
       max-width: unset !important;
    }
    
    .guests-container > div {
       box-shadow: none !important;
    }
    .action-buttons {
        background-color: unset;
    }
    .guests-container {
        grid-template-columns: repeat(auto-fill, minmax(50px, 135px));
    }
}
@container (max-width: 600px){
    .guest-picture > img {
        height: 5rem;
    }
    .guest-wrapper {
        min-height: unset;
    }
}

@media (max-width: 500px){
    .div-guest___container {
        flex-direction: row;
    }
    .nameField {
        margin-top: 0;
        padding-left: 8px;
        padding-right: 8px;
    }
    .guest-card-style {
        padding: 0 !important;
        box-shadow: unset;
    }
    .guest-picture > img {
        width: 5rem;
        border-radius: 50%;
    }
    .guest-picture {
        width: fit-content;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .guest-wrapper {
        border: unset;
    }
}

@media (max-width: 480px) { 
    .guests-container {
        grid-template-columns: repeat(auto-fill, minmax(50px, 109px));
    }
    .guest-card-style {
        box-shadow: none;
    }
    .guest-container {
        overflow: hidden;
    }
    .guests-container {
        margin: 0 -4px -4px 0;
    }
  
    .guest-card-style > button {
        right: 5px !important;
        top: 5px !important;
    }
    .nameField > div {
        gap: 5px;
    }
}