.tasks-list {
    list-style: none;
    padding: 0;
    width: 70%;
    display: grid;
    row-gap: 2rem;
    margin-bottom: 3rem;
}

.tasks-list__li {
    background: #ffffff;
    border-bottom: solid 3px rgb(151, 151, 151, 0.18);
    /* border-top: solid 3px rgb(151, 151, 151, 0.18); */
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    width: 100%;
}
.tasks-list__li:nth-child(1) {
    border-top: solid 3px rgb(151, 151, 151, 0.18);
}

.tasks-list__li__done {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 3px rgb(151, 151, 151, 0.18);
    background: #ffffff;
    text-decoration: line-through;
    width: 100%;
    color: #bebbbb;
}

.tasks-list__li__done > .custom-dropdown {
    width: fit-content;
}

.tasks-list__li__done > .todolist___checkbox_span:nth-child(1) {
text-decoration: line-through;
}
.todolist___checkbox_span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 7px;
}
.tasks-list__li__done > .custom-dropdown > .dropdown > a > span {
    font-style: initial;
}

.tasks-list__li > input {
    align-self: center;
}
.tasks-list__li > .custom-dropdown > .dropdown > a, 
.tasks-list__li__done > .custom-dropdown > .dropdown > a {
    top: -20px;
}
.tasks-list__li__done > .custom-dropdown > .dropdown > div {
    font-style: initial;
}
.tasks-list__li > form > input {
    width: 100%;
    /* border-radius: 5px;
    padding: 0.7rem;
    background-color: white; */
}
.todolist___checkbox_span > span {
    margin-left: 1rem;
}

.todolist___li-btns {
    display: flex;
    flex-direction: row;
}

.todolist___li-btns > button:nth-child(1) {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0px 5px;
    padding: .375rem 1.5rem;
    box-shadow: rgb(0 0 0 / 26%) 0px 1px 3px 0px;
    background-color: #f6f5f3;
    border-radius: 20px;
}

.todolist___li-btns > input {
    margin: 0px 5px;
    align-self: center;
}

.todolist___li-btns > button:nth-child(2) {
    background: transparent;
}

.todolist___checkbox_span > input[type="checkbox"] { 
    filter: invert(40%) hue-rotate(18deg) brightness(1.7);
    height: 2rem;
    width: 2rem;
  }

  @media ( max-width : 1224px ) {
 
} 

@media ( max-width : 1139px ) {
   
}

@media ( max-width : 500px ) {
    /* .tasks-list__li > div, .tasks-list__li__done > div {
        flex-direction: column;
    } */

    .todolist___checkbox_span  {
        justify-content: unset;
        align-self: end;
    }
}

@media ( max-width : 414px ) {
    .tasks-list__li > div, .tasks-list__li__done > div {
        padding: 1rem !important;
      }
}