.section-action-box {
    display: flex; 
    gap: 20px; 
    flex-direction: column; 
    align-items: flex-end; 
    margin-bottom: 20px
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.guest-container {
    position: "relative";
    z-index: 1;
}

.guest-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    border-radius: 5px;
}

.guest-form > form {
    width: 100%;
}

.guest-form > form > div {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 5px !important;
}

/* 
.guest-form > form > div > input {
    border-style: none;
    width: 80%;
    padding: 2rem;
}
.guest-form > form > div > img {
    height: fit-content;
    align-self: center;
    padding-left: 5px;
} */



.guest-form > form > div > input::placeholder {
    font: italic;
}

.guests___list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1312px) {

}
@media (max-width: 1024px) {
    .guest-form > div, form {
        width: 100%;
    }

    .guest-form > form > label {
        font-size: 1rem;
    }
    .guest-container > .container-fluid .row {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 913px) {
    .guest-form > form > label {
        font-size: 1rem;
    }
    .guest-container > .container-fluid .row {
        display: flex;
        justify-content: center;
    }  
}

@media (max-width: 768px) {
   
    .guest-form > form > label {
        font-size: 1rem;
    }
    .guest-container > .container-fluid .row {
        display: flex;
        justify-content: center;
    }
}



@media (max-width: 600px) {
    .byguests___block {
        padding: 2rem 4rem;
    }

    .guest-container {
        padding: 0 !important;
    }
    .section-action-box {
        padding: 0;
    }
}

@media (max-width: 540px) {
    .guest-form {
        display: flex;
        flex-direction: column-reverse;
    }
    .search__container {
        align-self: flex-end;
    }
    .guest-form > form {
        align-self: center;
        width: 100%;
    }
}

@media (max-width: 459px) {

    /* .div-guest___container {
        display: flex;
        width: 100%;
        margin-top: 0;
        flex-direction: row;
    } */
    .dropdown > a {
        position: absolute;
        top: -15px;
        right: -8px;
        cursor: pointer;
    }
    /* .guest-picture {
        margin-left: 3rem;
    } */
    .div-guest___container > .nameField {
        position: relative;
    }

}