.footer {
    background-color: #101213;
    color: beige;
    padding: 20px;
    width: 100%;
    z-index: 1000;
}

.footer-dims {
    display: flex;
    justify-content: center;
    position: relative;
    height: 40px;
}

.footer-dims___span > span > a {
    color: #DAAEBB;
}

.footer-dims___span, .footer-dims___links {
    width: fit-content;
}

.footer-dims___span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.footer-dims___links {
    position: absolute;
    top: 50%;
    left: 95%;
    transform: translate(-50%, -50%);
}

#enveloppe-logo {
    height: 35px;
}