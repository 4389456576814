.db-card-container {
    margin-top: 2rem;
}
.db-component-content, .db-component-count {
    font-size: 3vw;
    margin-bottom: 2rem;
    min-height: 50px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.db-component-content > span {
    font-size: 2.2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.db-component-count > span {
    font-size: 2.3vw;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#dashboard-cards {
    width: auto;
    max-width: 300px;
    position: relative;
    padding-right: 2rem;
    padding-left: 2rem;
    border-radius: 20px;
}

/* #dashboard-cards:nth-child(2) {
    font-size: 3rem;
} */

.db-component-style > h3 {
    font-size: 1rem;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
}

.dashbord-content {
    padding: 0;
    text-align: center;
    margin-top: 1rem;
}

.dashbord-content > li {
    word-break: break-word;
}

.dashbord-content > div {
    word-break: break-word;
}

.dashbord-return-content {
    margin-bottom: 5rem;
    text-align: center;
    width: 100%;
}

.dashbord-view-details {
    bottom: 10%;
    position: absolute;
    left: 0;
    right: 0;
}

.overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard-cards {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
    border: solid 1px rgb(151, 151, 151, 0.18);
    padding: 3rem !important;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    height: 100%;
    justify-items: center;
    text-align: center;
    background-color: #fff;
}

@media only screen and (max-width : 1218px) {
    .dashboard-cards {
        padding: 3rem 2rem !important;
    }
}

@media (max-width: 954px) { 
    .db-component-count > span {
        font-size: 4vw;
    }    
}

@media (max-width: 576px) { 
    .db-component-count > span {
        font-size: 2.2rem;
    }    
}