

form > .form-group > span {
	font-size: 80%;
    color: darkred;
	padding: 0.5rem 1rem;
}

.login__row__icon > svg {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	overflow: visible;
}

.svg-icon {
	cursor: pointer;
}

.svg-icon path {
	stroke: rgba(255, 255, 255, 0.9);
	fill: none;
	stroke-width: 1;
}

input {
	outline: none;
	border: none;
}

.login-grid {
	display: flex;
	height: 100%;
}

.login-grid > .grid-item-2 {
	height: fit-content;
	padding: 0 1rem;
	width: 100%;
	background-color: #FFF;
	margin-bottom: 3rem;
	margin-top: 1rem;
}

.demo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* margin-left: -15rem;
	margin-top: -26.5rem; */
	width: 30rem;
	height: 90%;
	/* overflow: hidden; */
}

.login {
	display: flex;
    flex-direction: column;
	width: 100%;
	margin: 0 auto;
	gap: 20px;
	max-width: 23rem;
}

.login.inactive {
	opacity: 0;
	transform: scale(1.1);
}

.login__form > form {
	display: flex;
	flex-direction: column;
	gap: 15px;
}


.login__form > form > div {
	display: flex;
	flex-direction: column;
}

.login__submit {
	align-items: center;
}

.login__row {
	display: flex;
	margin-bottom: 20px;
    min-height: 50px;
	width: 100%;
}

.login__row__icon {
    display: flex;
    align-self: center;
}

.login__icon {
	margin-bottom: -0.4rem;
	margin-right: 0.5rem;
}

.login__icon.name path {
	stroke-dasharray: 73.50196075439453;
	stroke-dashoffset: 73.50196075439453;
	animation: animatePath 2s 0.5s forwards;
}

.login__icon.pass path {
	stroke-dasharray: 92.10662841796875;
	stroke-dashoffset: 92.10662841796875;
	animation: animatePath 2s 0.5s forwards;
}

#login__input {
	display: inline-block;
	padding-left: 1.5rem;
	padding-bottom: 10px;
	height: 100%;
	background: transparent;
	color: #FDFCFD;
	border-bottom: 1px solid #FFFFFF;
}

.login__signup {
	color: #ABA8AE;
	height: 15%;
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.login__signup:nth-child(even) {
    text-align: center;
}

.forgotten-password {
    align-self: flex-end;
}

.login__signup a {
	color: #000000;
	cursor: pointer;
	text-decoration: underline;
}


.ripple {
	position: absolute;
	width: 15rem;
	height: 15rem;
	margin-left: -7.5rem;
	margin-top: -7.5rem;
	background: rgba(0, 0, 0, 0.4);
	transform: scale(0);
	animation: animRipple 0.4s;
	border-radius: 50%;
}

@keyframes animRipple {
	to {
		transform: scale(3.5);
		opacity: 0;
	}
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

@keyframes animatePath {
	to {
		stroke-dashoffset: 0;
	}
}

@media (max-width: 767px) {
	.login-grid > .grid-item-2 {
		width: 100%;
		display: initial;
	}
}

@media (max-width: 414px) {
	.login {
		padding: 40px;
	}
}