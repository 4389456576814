#todo-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

#todo-form > input {
    background: transparent;
}

.todo-form___update-btns {
    display: flex;
    flex-direction: column;
}

#todo-form > div > button:nth-child(2){
    background: transparent;
}