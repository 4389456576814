.box-content__title {
    width: 100%;
    color: #000;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
    border: solid 1px rgb(151, 151, 151, 0.18);
    background-color: #fff;
    margin-bottom: 50px;
}
.box-content__title h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: Playfair Display, serif;
}
@media (max-width: 500px){
    #guestlist-content{
        display: flex;
        flex-direction: column;
        gap: unset;
        padding: 0;
    }
    .guest-wrapper {
        padding: 0;
        height: unset;
        min-height: unset;
    }
    .box-content__title {
        padding-top: 30px;
        padding-bottom: 20px;
        box-shadow: unset;
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .box-content__title h3 {
        font-size: 1.2rem;
    }
}
