.pie {
    /* height: 15rem; */
    margin-top: 2rem;
}

.MuiCollapse-wrapper {
    justify-content: end;
}

.MuiCollapse-wrapperInner {
    width: 40% !important;
    margin-top: 50px;
}

.MuiCollapse-wrapperInner > canvas {
    height: 15rem !important;
    width: auto !important;
}
.chart-component {
    padding: 0;
}

@media (max-width: 1200px) { 
 
 }


@media (max-width: 1024px) { 
    .budget___col-2 {
        flex-direction: column-reverse;
        align-items: center;
    }
    .budget___col-2 > .chart-component {
        width: 100% !important;
        margin-bottom: 2rem;
        padding: 0;
    }
    .budget___col-2 > div {
        padding: 0;
    }
    /* .pie {
        height: 14rem;
    } */
 }
/* @media (max-width: 575.98px) { 
    .pie {
        height: 29rem;
    }
    .budget___col-1 > .card-component {
        width: 60%;
    }
 } */

 /* @media (max-width: 444px) { 
    .budget___col-1 > .card-component {
        width: 100%;
    }
    .pie {
        height: 32rem;
    }
    .budget___col-2 {
        margin-top: 3rem;
    }
 } */