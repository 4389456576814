label {
    text-align: start;
    /* font-size: 0.7rem; */
}

.error {
    /* position: absolute; */
    color: darkred;
    /* font-size: .8rem; */
    text-align: start;
    /* font-weight: 500; */

}

.textfield-style {
    /* margin-top: 15px; */
    /* padding-left: 1.5rem; */
    display: flex;
    flex-direction: column;
    /* width: 50%; */
}

.textfield-style > input {
    border-radius: 5px !important;
    padding: 1.5rem;
}

.input-style {
    border-bottom: 1px solid;
    width: 100%;
}

.bd-radius {
    border-radius: 10px !important;
}