


/* Mise en forme */
html, body {
    min-height: 100vh;
    width: 100%;
    margin: 0;
    font-weight: 400 !important;
    font-size: 0.9rem;
    /* font-family: 'Playfair Display', serif !important; */
    font-family: "Karla", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    background-color: #f9f9f9 !important;
}
.MuiFormControlLabel-label {
    font-family: "Karla", sans-serif !important;
    letter-spacing: unset !important;
}
.MuiChip-label {
    font-family: "Karla", sans-serif !important;
    font-size: 1rem;
    letter-spacing: -1px;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}
li {
    list-style: none;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    min-height: 91vh;
}

#align-items {
    align-items: center;
}

.content-home {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-top: 60px;
}

/********Navigation*********/
.navigation-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigation-layout > nav {
    /* position: fixed;
    width: 100%;
    z-index: 1000; */
    /* box-shadow: 0px 5px 5px 0px rgba(173,164,173,0.5); */
}

.nav-logo > img {
    height: 100%;
}

/*********App Content*******/
.body-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 64px;
    max-width: 1920px;
    /* position: relative; */
    /* top: 64px; */
    
}

.body-content___home {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #FFFFFF;
}

.nav-open .App-home {
    margin-left: 330px; /* Réduit la marge lorsque la navigation est ouverte */
  }
.App-home {
    min-height: 91vh;
    flex: 1;
    margin-left: 70px; /* Marge pour laisser de la place à la sidebar */
    transition: margin-left 0.3s ease; /* Animation de transition de marge */
}

.App {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

/******Components******/

button:focus {
    outline: none;
}

.center-x {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* 
.search__container {
    width: 100%;
    display: flex;
    border: lightgray 1px solid;
    padding: 0 0.5rem;
    border-radius: 10px;
    background: #FFFFFF;
    padding: .375rem .375rem .375rem .75rem;
    /* box-shadow: 7px 6px 25px -6px rgba(158,153,158,1); 
    text-align: center;
} */

/* Boutons */
.button {
    height: fit-content;
}

.button-center {
    text-align: center;
}

.button button {
    border-style: none;
    height: 30px;
    padding: 3px 6px;
    font: unset;
    margin: 20px 0px 20px 0px;
}

.searchbar {
    display: flex;
    justify-content: flex-end;
}
/* Admin Login */
.loginAdmin {
    height: 100vh;
    width: 100%; 
    /* background-color: green; */
 }

.adminForm {
    height: fit-content;
    width: 100%;
    /* background-color: rosybrown; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginForm form {
    margin-bottom: 15px ;
}

a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none ;
}

/* Espace Perso */
.espacePersoBox {
    height: 67%;
    width: 100%;
    /* background-color: rosybrown; */
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* Menu groupes */
.groupes {
    /* height: 100vh; */
    width: 100%; 
    /* background-color: green; */
}

.groupesBox {
    /* height: 67%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.groupesBox form {
    width: 60%;
    height: 148px;
    display: flex;
    border: black;
    border-style: solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleSection {
    font-size: 2.25rem;
}

.groupID {
    height: 20px;
    width: 100%;
    background-color: orange;
}

.addGroupBtn {
    height: 50px;
}

.addGroup {
    height: 22%;
    width: 30%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: red;
    border-style: dotted;
    list-style: none;
    margin: 10%;
}

.addGroupCroix {
    font-size: 63px;
    margin-bottom: 15px;
}

.divSelectGroup {
    text-align: center;
}

/* Menu menus */
.menus {
    height: 100vh;
    width: 100%; 
    background-color: green;
}

.menusBox {
    height: 67%;
    width: 100%;
}

.addMenu {
    height: 22%;
    width: 30%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: red;
    border-style: dotted;
    list-style: none;
    margin: 10%;
}

.addMenuCroix {
    font-size: 63px;
    margin-bottom: 15px;
}

/* Menu gâteau */
/* Menu menus */
.cake {
    height: 100vh;
    width: 100%; 
    background-color: green;
}

.cakeBox {
    height: 67%;
    width: 100%;
}

.addCake {
    height: 22%;
    width: 30%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: red;
    border-style: dotted;
    list-style: none;
    margin: 10%;
}

.addCakeCroix {
    font-size: 63px;
    margin-bottom: 15px;
}

@media (max-width: 954px) { 
    .sidebar {
        display: none;
    }
    
}

@media (max-width: 768px) { 
    h2 {
        /* font-size: 2rem; */
        text-wrap: balance;
    }

    .searchbar, .guest-form  {
        width: 100% !important;
    }
    .App-home {
        position: absolute;
        width: 100%;
        left: 0px !important;
    }
    .nav-open .App-home {
        margin-left: 70px;
    }
 }
 .search__container {
    width: 100%;
    max-width: 27rem;
    display: flex;
    border: lightgray 1px solid;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 0.3rem 0.7rem;
    text-align: center;
 }
 .center-h-v{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
 }

 @media (max-width: 600px) {
    .byguests___block {
        padding: 0 !important;
    }
    .search__container {
        max-width: unset;
    }
    .select-guest {
        max-width: unset !important;
    }
    .select-family {
        max-width: unset !important;
    }
    .App-home {
        position: unset;
        left: unset;
        margin: 0px !important;
    }
 }

