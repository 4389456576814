ul {
    list-style: none;
}

#food-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}
#reception-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section-action-box {
    display: flex; 
    gap: 20px; 
    flex-direction: column; 
    align-items: flex-end; 
    margin-bottom: 20px
}
@media (max-width: 600px) {
    .section-action-box {
        padding: 0;
    }
}