#home-screens-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 200px;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
}
#home-screens {
    position: relative;
    max-width: 1175px;
    width: 100%;
    display: flex;
    justify-content: center;
}
#home-screens img {
    width: 100%;
    max-width: 1120px;
    box-shadow: 9px 11px 15px -3px rgba(0,0,0,0.15);
    border-radius: 20px;
    border: 1px solid #f2f0f0;
}

@media (max-width: 960px){
    #home-screens-wrapper{
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 150px;
    }
}