.content-home > .banner-section {
    width: 100%;
    display: flex;
    height: 650px;
    background-image: url("../../img//homepage-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-position: calc(50% + 250px) 50px,100% !important; */
    /* background-position-y: -247px; */
    position: relative;
 }

 .content-home > .banner-section > .banner-text {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 68px;
 }

 .content-home > .banner-section > .banner-text > span:nth-child(1) {
    font-size: 2.5rem;
    color: #FFF;
    text-transform: uppercase;
 }

 .content-home > .banner-section > .banner-text > span:nth-child(3) {
    font-size: 6rem;
    color: #FFF;
    text-transform: uppercase;
 }

 .intro-section {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding-top: 50px;
    padding-bottom: 50px;
    max-height: 700px;
 }

 .intro-section > .container > {
    display: flex !important;
    flex-direction: row !important;
 }

 .csctn {
   display: flex !important;
   flex-direction: row;
   height: 100%;
 }

 .intro-section > .container > .intro {
    color: black;
    padding: 2rem;
    width: 50% !important;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
 }

 .intro-section > .container > .intro > h1 {
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1em;
}

 .intro-section > .container > .intro > p {
    line-height: 2em;
}

 .intro-section > .container > .intro > p > span {
    display: block;
    /* font-size: 1.3rem; */
    text-wrap: balance;
}

.intro-section > .container > .intro-img  {
    padding: 2rem;
    width: 50% !important;
    display: flex;
    justify-content: flex-end;
 }

.intro-img > img {
    width: auto;
    height: 100%;
    max-height: 400px;
}

.home-btn {
    position: relative;
    bottom: 30%;
    transform: translateY(-30%);
    color: black;
}

.intro > .register-btn {
    width: 100%;
}

.intro > .register-btn > button > a:hover {
    background-color: black !important;
    color: white !important;
}

.intro > .register-btn > button{
    background-color: black;
    color: white !important;
    border-radius: 25px;
    padding: 10px;
    text-transform: unset;
    width: 20rem;
}

.intro > .register-btn > button:hover{
    background-color: black;
}

.presentation-section {
    background-color: #F0EEEC;
    padding-top: 150px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
}


.home-link {
    border-radius: 20px;
    font-size: 1rem;
    color: whitesmoke;
}

.home-link > a {
    text-decoration: none;
}

a:hover {
    color: black;
}

.section-2___titre {
    text-align: center;
    margin-bottom: 25px;
}

.section-2___titre > h2 {
    font-size: 2rem !important;
    font-weight: 900;
}

.section-2___sous-titre {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
}
.section-2___sous-titre > span {
    font-size: 2rem;
}

.home-cards {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
    border: solid 1px rgb(151, 151, 151, 0.18);
    padding: 3rem 5rem !important;
    flex-direction: column;
    max-width: 400px;
    height: 100%;
    display: flex;
    border-radius: 10px;
}

.home-cards__img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: #f7f6f4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-cards__title {
    margin-top: 20px;
}
.home-cards__title > h3 {
    font-size: 1.2rem;
    font-weight: 700;
}

.intro-card{
    padding: 2rem !important;
    background-color: #f4f4f3;
    border-color: #dcdad9;
    box-shadow: none !important;
    /* border: none !important; */
    gap: 7px;
    margin-top: 2rem;
    width: -webkit-fill-available;
    height: auto;
    max-width: 300px;
}

#details-description > .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
}


@container (max-width: 1200px){
    #details-description > .cards-container {
        /* grid-template-columns: repeat(2, 1fr); */
    }
   
  }

@media (max-width: 1199.98px) { 
    .home__picture > img {
        margin-top: 20%;
    }
    .intro > h1 {
        font-size: 3rem;
    }
 }

 @media (max-width: 991.98px) { 
    .home__picture > img {
      margin-top: unset;
      height: 325px;
      width: auto;
  }
  .intro > p > span {
    font-size: 1rem;
    line-height: 1.5em;
}
  .intro > p {
      margin-right: 0;
  }
  .section-2___sous-titre > span {
    font-size: 2rem;
    }
    .banner-section {
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    /* .presentation-section {
        border-top-left-radius: 300px;
        border-top-right-radius: 300px;
    } */
  }

  
@container (max-width: 1200px){
    #details-description > .cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
    /* #details-img {
        max-width: 300px;
    } */
  }

  @media (max-width: 767.98px) { 
    .content-home > .banner-section {
        height: 450px;
    }
    .content-home > .banner-section > .banner-text {
        line-height: 45px;
    }
    .content-home > .banner-section > .banner-text > span:nth-child(1) {
        font-size: 1.6rem;
    }
    .content-home > .banner-section > .banner-text > span:nth-child(3) {
        font-size: 4rem;
    }
    .home___register-btn > button {
        width: 14rem;
        padding: 7px
    }

    .intro > h1 {
        width: 100%;
    }
    .csctn  {
        flex-direction: column;
    }

    .intro-section {
        height: fit-content;
        max-height: unset;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .intro-section > .container > .intro {
        width: 100% !important;
        padding: 1rem;
    }

    .intro-section > .container > .intro > p > span {
        font-size: 1.2rem;
        text-wrap: unset;
    }

    .intro > .register-btn {
        display: flex;
        justify-content: center;
    }

    .intro-section > .container > .intro-img {
        width: 100% !important;
        justify-content: center;
    }

    .intro-img > img {
        height: 350px;
        width: 100%;
        object-fit: cover;
    }

    /* #details-description > .cards-container {
        padding-left: 0;
        grid-template-columns: repeat(2, 1fr);
    } */
    .home-cards__title > h3 {
        font-size: 1rem;
    }
    .home-cards__img {
        height: 4rem;
        width: 4rem;
    }
    .section-2___titre > h2 {
        font-size: 2rem !important;
    }
    .section-2___sous-titre > span {
        font-size: 1.2rem;
    }
    .presentation-section > .container {
       margin: 0;
       width: 100%;
       max-width: unset;
    }
    .presentation-section {
        padding-top: 150px;
        padding-left: 40px;
        padding-right: 40px;
    }
   }

   @media (max-width: 576px) {
    .content-home > .banner-section {
        height: 350px;
    }
    .content-home > .banner-section > .banner-text {
        line-height: 35px;
        top: 37%;
    }
    .content-home > .banner-section > .banner-text > span:nth-child(1) {
        font-size: 1rem;
    }
    .content-home > .banner-section > .banner-text > span:nth-child(3) {
        font-size: 2.4rem;
    }
    .section-2___titre > h2 {
        font-size: 1.5rem !important;
    }
    .home__picture > img {
        height: auto;
        width: inherit;
    }
    .presentation-section {
        border-radius: 0;
        padding-top: 90px;
    }
}

@media (max-width: 500px){
    .presentation-section {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (max-width: 400px){
    .presentation-section {
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-2___titre {

    }
    /* #details-description > .cards-container {
        grid-template-columns: 1fr;
    } */
}