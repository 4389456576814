.tables-form > form > label {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.3px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
}

.tables-form > form {
    padding: 20px;
    width: 100%;
    text-align: center;
}

.tables-form > form > input {
    border: 1px solid lightgray;
}

.tables___block {
    width: 100%;
    height: fit-content;
}

.tablesBox {
    height: 67%;
    width: 100%;
}

.addTable {
    height: 22%;
    width: 30%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: red;
    border-style: dotted;
    list-style: none;
    margin: 10%;
}

.addTableCroix {
    font-size: 63px;
    margin-bottom: 15px;
}

.table-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    border-radius: 5px;
}

.table-form > form {
    width: 100%;
}

.table-form > form > div {
    width: 100%;
    display: flex;
    padding: 0 0.5rem;
    border-radius: 5px;
    background: #FFFFFF;
    padding: .375rem .375rem .375rem .75rem;
    justify-content: space-around;
}

.table-form > form > div > input {
    border-style: none;
    width: 80%;
    border: 1px solid lightgrey;
    border-radius: 10px !important;
    /* padding: 2rem; */
}

.table-form > form > div > input::placeholder {
    font: italic;
}

.table-form > form > div > button {
    margin-left: 10px;
    border-style: none;
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: #b2a9a9;
    color: #FFF;
    min-width: 8rem;
    transition: 0.2s all ease-in-out;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 26%);
    text-shadow: 0px 0px 1px #333;
}


.table-form > form > div > button:hover {
    background: #e0d9d9;
    color: #FFF;
    animation: none;
}
.table-form > form > div > button:focus {
    color: #FFF;
    outline: none;
}

.table-form > form > div > button:active {
    color: #D18476;
}

.tbStyle {
    display: flex;
    flex-direction: column;
    /* box-shadow: 7px 6px 9px -6px rgb(158 153 158); */
    height: auto;
    background-color: whitesmoke;
    position: relative;
    align-items: center;
    padding: 2rem 2rem;
    border-bottom: 3px #dfdbd7 solid;
    border-radius: 7px;
}

.tbStyle > .menu-icon {
    position: absolute;
    bottom: 84%;
    left: 89%;
    color: #D3D3D3;
    cursor: pointer;
}
.tbStyle > .custom-dropdown{
    position: absolute;
    bottom: 86%;
    left: -5%;
}

.select-style {
    width: 100%;
}

.select-style > div > div > div > div {
    padding: 0px 5px;
}

.delete-table {
    position: absolute;
    bottom: 83%;
    left: 5%;
    color: #D3D3D3;
}

.delete-table:focus {
    outline: none;
}


.icon-style {
    cursor: pointer;
}

.guest-del {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
}

.guest-del > button {
    background-color: #d30606;
    color: #fff;
    border-radius: 5px;
}

.guest-del > button:focus {
    outline: none;
}

.undo-btn {
    background-color: transparent;
}

.undo-btn:focus {
    outline: none;
}

.block {
    border-radius: 10px;
 }

 .block > span {
    font-size: 20px;
    font-weight: 500;
}

.dots-menu {
    position: absolute;
    right: 28px;
}

.dots-menu-edit {
    position: absolute;
    right: 29px;
    top: 43px;
}

.render-tables {
    width: 100%;
    border-radius: 5px;
    border: solid 1px rgb(151, 151, 151, 0.18);
    padding: 3rem 5rem !important;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 3rem 1rem !important;
    /* height: auto !important; */
    min-height: 25rem;
    background-color: #fff;
    max-width: 300px !important;
    /* min-width: 250px !important; */
    height: 100%;
    justify-items: center;
    text-align: center;
    display: flex;
}

.table-name-span {
    font-size: 1rem;
    text-align: center;
    line-height: 23px;
    margin-bottom: 2rem;
    font-family: "Playfair Display", serif;
    letter-spacing: 1px;
    color: #878a8c;
    text-transform: uppercase;
}

.div-table-name-span {
    min-height: 80px;
    text-align: center;
    word-break: break-word;
}

#table-form-style {
    padding: 0 15px;
}

.edit-table-btn {
    padding: 6px 25px;
    font-size: 1rem;
}

.table-view-details {
    position: absolute;
    bottom: 10%;
}

.table-card__button-container {
    position: absolute;
    bottom: 30px;
}

.table-card__button-container > button {
    text-transform: unset;
    background-color: #262626;
    color: #fff;
    font-family: unset;
    border-radius: 36px;
    padding-right: 30px;
    padding-left: 30px;
}

.MuiInputBase-input {
    /* font-family: 'Playfair Display', serif !important; */
}
#tables-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

 @media (max-width: 768px) { 
    .table-form {
        width: 100% !important;
    }
    .edit-form {
        font-size: 1.3rem;
    }

    .add-form > form {
        width: 80%;
    }

    .render-tables {
        min-width: 200px !important;
    }
 }

 @media (max-width: 600px) {
    .tables-grid {
        padding: 0 !important;
    }
}