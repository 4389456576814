#home-signup-wrapper {
    height: 200px;
    max-height: 200px;
    width: 100%;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}
#signup-box {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translate(-50%, -20px);
    width: 100%;
    max-height: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #D2D3D1;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 14%);
}
#signup-box span:nth-child(1){
    font-weight: 900;
    text-align: center;
    font-size: 1.3rem;
}
#signup-gutter {
    visibility: hidden;
    text-align: center;
    font-size: 0.85em;
    margin-top: 8px;
    text-align: center;
    font-size: 1rem;
    margin-top: 8px;
    color: #f3f3f3;
}
#signup-box-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
    gap: 5px;
    z-index: 10;
}
#signup-box-form label {
    /* visibility: hidden; */
    display: none;
}
#signup-box-form input {
    width: 100%;
    height: 40px;
    border-radius:5px;
    border: solid 1px #D2D3D1;
    padding-left: 10px;
    max-width: 330px;
}
#signup-box-form button {
    border-radius: 5px !important;
}
#home-box-email::-webkit-input-placeholder {
    color: #D2D3D1;
}
#home-box-email:-moz-placeholder {
    color: #D2D3D1;
}
#home-box-email::placeholder {
    color: #D2D3D1;
}
@media (max-width: 900px){
    #signup-box {
        /* border: unset; */
        top: unset;
        position: unset;
        transform: unset;
        max-height: unset;
        height: unset;
        background: unset;
        border: unset;
        box-shadow: unset;
        /* height: 170px; */
        /* background-color: unset; */
        /* box-shadow: unset; */
    }
    #home-signup-wrapper {
        height: 300px;
        max-height: unset;
        position: unset;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #signup-box-form input {
        width: unset;
        flex: 1;
        height: 50px;
    }
    #signup-box span:nth-child(1){
        display: none;
    }
    #signup-gutter {
        visibility: visible;
    }
}
@media (max-width: 600px){
    #signup-box-form input {
        flex: unset;
        max-width: unset;
    }
    #signup-box-form {
        flex-direction: column;
        gap: 10px;
    }
    #signup-box-form button {
        padding-left: 10px !important;
        padding-right: 10px !important;
        height: 40px;
    }
}
@media (max-width: 380px){
    #signup-box-form input {
        border-radius: 5px;
        /* height: 50px; */
        flex: unset;
    }
    #signup-box-form button {
        border-radius: 10px !important;
        height: 40px !important;
    }
}