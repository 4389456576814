.banner {
    height: 15rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

@media (max-width: 786px) {
    .banner {
        border-radius: 0;
        margin: 0;
    }
}

@media (max-width: 600px) {
    .banner {
        height: 10rem;
    }
}