.header {
    height: 60px;
    width: 100%;
    position: absolute;
    z-index: 1000;
}

.navigation {
    background-color: #fff;
    height: 100%;
    width: 100%;
    /* clear: both; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5rem;
}

.topNavbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 64px;
    padding: 0 2rem;
    background-color: white;
    color: darkgrey;
    z-index: 999;
    max-width: 1920px;
}
.topNavbar > div {
    padding: 1rem;
}
.navbar__links {
    display: flex;
    margin-bottom: 0;
}

.navbar__item > a {
    padding: 0 .3rem;
    color: #000;
    text-decoration: none;
    /* color: #F7F5F3; */

}

.navbar__burger {
    width: 20px;
    height: 12px;
    background: transparent;
    border: none;
    display: none;
}


.nav-logo {
    height: 100%
}

#brand {
    height: 100%;
    display: flex;
    align-items: center;
}

#brand > a {
    height: 60%;
}

#brand > a > img {
    height: 100%;
}

.div-logo {
    cursor: pointer;
    height: 100%;
}
.div-logo > img {
    height: 100%;
}


.navbar-menu {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
}

.navbar-menu > li {
    display: inline-block;
    margin: 0 10px;
    padding: 0.7rem 1rem;
    background: #000;
    border-radius: 3px;
}

.navbar-menu > li > a, .navbar-menu > li > button {
    color: #fff;
    text-decoration: none;
}
.hidden-links {
    display: none;
}

/* Sidebar */

.navbar-side {
    place-content: flex-end;
}

.dropdown-item {
    font-size: 0.7rem;
}

.dropdownmenu {
    background-color: #F8F9FA;
}

.sidebar {
    height: 91vh;
    background-color: #fcfaf7;
    position: sticky;
    top: 64px;
    height: 100vh;
    width: 100%;
    max-width: 330px;
    border-right: 1px solid #f2f2f2   ;
}

.sidebarList {
    height: auto;
    padding: 15px;
    width: 100%;
    padding-left: 65px;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.sidebarList > .menu-row {
    width: 100%;
    height: 2.5rem;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    color: #a4a4a5;
    letter-spacing: 0.5px;
}

.sidebarList > .menu-row:hover {
    cursor: pointer;
    /* background-color: #a0a09b; */
}

.menu-row > #active {
    color: #000;
}

.menu-row > a {
    color: #a4a4a5;
}

.menu-row #icon {
    flex: 30%;
    display: grid;
    place-items: center;
}

.menu-row #title {
    flex: 70%;
}
.sidebar__greetings {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    word-break: break-word;
    text-align: center;
    align-items: center;
}
.sidebar__greetings > p{
    word-break: break-word;
}

.profile-picture {
    max-height: 10rem;
    max-width: 10rem;
    margin-bottom: 1rem;
}

.profile-picture > img {
    border-radius: 50%;
    max-height: inherit;
    border: 7px solid #D8BF99;
}

.greetings {
    font-size: 2rem;
    /* font-weight: 600; */
    margin-bottom: 0;
}
.sidebar__greetings > .names {
    font-size: 2.5rem;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 55%);
}
.bg-desktop {
    background-color:  #fff;
}

#showNavLinks {
    display: block;
    align-self: center;
}

#showNavLinks > button {
    display: inline-block;
    margin: 0 10px;
    padding: 0.7rem 1rem;
    background: transparent;
    border-radius: 3px;
    /* color: whitesmoke; */
}

.mywedding-nav > span {
    color: #383535;
}

#logo {
    display: block;
}

.active {
    color: white;
    font-weight: 800;
    background: #000000;
    border-radius: 5px;
    }

@media only screen and (max-width : 1150px) {
    .sidebar {
        /* width: fit-content; */
        /* max-width: unset; */
    }
    }

@media screen and (max-width: 600px) {
    .active {
    background: unset;
    border-radius: 5px;
    }
    .active > span {
        font-weight: 900 !important;
    }
    .glass {
        position: relative;
        background: inherit;
        overflow: hidden;
        width: 70%;
        height: 70%;
    
        /* z-index: 1; */
      }
      
      .glass:before {
        content: '';
        position: absolute;
        background: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -20px;
        box-shadow: inset 0 0 500px rgba(255, 255, 255, .4); 
        filter: blur(10px);
        z-index: -1;
      }

      
    .hidden-links {
        display: block;
    }
    .navbar__links {
        flex-direction: column;
        align-items: center;
        position: fixed;
        visibility: hidden;
        margin: 0;
        right: -100vw;
        bottom: 0;
        width: 0;
        height: 100vh;
        padding: 2rem;
        /* background: #BCBCAD; */
        /* slide in and out nav */
        transition: all .8s ease-out;
    }
    .show_nav .navbar__links {
        right: 0;
        width: 100vw;
        visibility: visible;
        /* opacity: 0.9; */
        justify-content: center;
    }
    
    .navbar__link {
        display: block;
        padding: 1rem !important;
        font-size: 5vw;
        background: inherit;
        color: #F7F5F3 !important;
    }
    .navbar__burger {
        display: block;
        position: fixed;
        /* top: 2rem; */
        right: 4rem;
    }
    .navbar__burger:hover {
        cursor: pointer;;
    }
    .burger_bar,
    .burger_bar::before,
    .burger_bar::after {
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: #4A4E69;
        transition: all .5s ease-in-out;
    }
    .burger_bar::before,
    .burger_bar::after {
       content: "";
       position: absolute;
       left: 0;
    }
    .burger_bar::before {
        transform: translateY(-12px);
    }
    .burger_bar::after {
        transform: translateY(12px);
    }
    .show_nav .burger_bar {
        width: 0;
        background: transparent;
    }
    .show_nav .burger_bar::before {
        transform: rotate(45deg);
    }
    .show_nav .burger_bar::after {
        transform: rotate(-45deg);
    }
    .navbar__item > button {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }
    
    .navbar__item > button:hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }
    
    .navbar__item > button:active {
        outline: none;
        border: none;
    }
    
    .navbar__item > button:focus {
        outline: 0;
    }
    .navbar__item::after {
        content:"";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background: #FFF;
    }
    .navbar__item:last-child::after {
        display: none;
    }

    #showNavLinks > button {
        color: #000;
    }

    /* animation li */
    .navbar__item {
        transform: translateY(100vh);
    }
    .show_nav .navbar__item {
        transform: translateY(0);
    }
    .show_nav .slideDown-1 {
        transition: all 1s ease-out;
    }
    .show_nav .slideDown-2 {
        transition: all 1.1s ease-out;
    }
    .show_nav .slideDown-3 {
        transition: all 1.2s ease-out;
    }
    .show_nav .slideDown-4 {
        transition: all 1.3s ease-out;
    }
    .show_nav .slideDown-5 {
        transition: all 1.4s ease-out;
    }
    .show_nav .slideDown-6 {
        transition: all 1.5s ease-out;
    }
    .show_nav .slideDown-7 {
        transition: all 1.6s ease-out;
    }
    .show_nav .slideDown-8 {
        transition: all 1.7s ease-out;
    }

    .navbar__item > a {
        color: #F7F5F3;
    
    }
}

@media (max-width: 576px) {
    .mywedding-nav > span {
        display: none;
    }
    .navigation {
        padding-left: 25px;
        padding-right: 25px;
    }
}